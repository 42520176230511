<template>
    <div class="pagination">
        <el-pagination
            v-if='pageTotal > 0'
            background
            :page-sizes="pageSizes"
            :page-size="pageSize"
            :total="pageTotal"
			layout="prev, pager, next"
            :current-page='pageIndex'
            @current-change='handleCurrentChange'
            @size-change='handleSizeChange'
			@prev-click='handelPrev'
			@next-click='handelNext'
			hide-on-single-page="true"
			>
        </el-pagination>
    </div>
</template>


<script>
  export default {
      name:'pagination',
      data(){
          return {
            paginations: {
                pageIndex: 1,  // 当前位于哪页
                pageSize: 10,   // 1页显示多少条
                pageSizes: 20,  //每页显示多少条
                layout: "total, sizes, prev, pager, next, jumper"   // 翻页属性
            },
          }
      },
      props:{
          pageTotal:Number,
		  pageSizes:Number,
		  pageSize:Number,
		  pageIndex:Number,
      },
      created(){
      },
      mounted(){
      },
      methods:{ 
           // 上下分页 pageIndex
          handleCurrentChange(page){
              this.$emit('handleCurrentChange',page);
          },
           // 每页多少条切换 pageSize
          handleSizeChange(page_size){
              this.$emit('handleSizeChange',page_size);
          },
		  handelPrev(page_size){
		      this.$emit('handelPrev',page_size);
		  },
		  handelNext(page_size){
		      this.$emit('handelNext',page_size);
		  }
		  
      }
  }
</script>

<style lang="sass">
   
</style>
<template>
	<div class="bg-white">
		<div class="container eurail-list">
			<div class="list-title">
				<a href="#">
					<span>一起飞国际机票网</span>
				</a>
				<i class="iconfont icon-arrow-right icon-chalv-xiangzuo"></i>
				<a href="#"><span>欧铁</span></a>
				<i class="iconfont icon-arrow-right icon-chalv-xiangzuo"></i>
				<span>通票列表页</span>
			</div>
			<div class="type">
				<span class="type-t">类别:</span>
				<ul>
					<li><span class="active">全部</span></li>
					<li><span>单国自选通票</span></li>
					<li><span>二国自选通票</span></li>
					<li><span>三国自选通票</span></li>
					<li><span>四国自选通票</span></li>
					<li><span>多国自选通票</span></li>
					<li><span>经典通票</span></li>
				</ul>
			</div>
			<div class="type">
				<span class="type-t">国家/地区:</span>
				<ul class="country">
					<li><span class="active">全部</span></li>
					<li><span>瑞士</span></li>
					<li><span>法国</span></li>
					<li><span>意大利</span></li>
					<li><span>英国</span></li>
					<li><span>比利时</span></li>
					<li><span>捷克</span></li>
					<li><span>奥地利</span></li>
					<li><span>塞班</span></li>
					<li><span>波士顿</span></li>
					<li><span>南极</span></li>
					<li><span>瑞士</span></li>
					<li><span>法国</span></li>
					<li><span>意大利</span></li>
					<li><span>英国</span></li>
					<li><span>比利时</span></li>
					<li><span>捷克</span></li>
					<li><span>奥地利</span></li>
					<li><span>塞班</span></li>
					<li><span>波士顿</span></li>
					<li><span>南极</span></li>
				</ul>
				<div>
					<el-input type="text" value="" class="form-control list-input" placeholder="请输入国家" />
					<button type="button" class="list-search">搜索</button>
				</div>
			</div>
			<div class="clearfix">
				<div class="list-left">
					<div class="list-i" style="overflow: hidden;" v-for="(item,index) in [1,2,3,4]" :key="index">
						<div class="img-l fl">
							<img src="../../assets/insurance/insurancebg-3.jpg" alt="" style="width:100%;height:100%">
						</div>
						<div class="fr content-r">
							<div class="title-box">
								<div class="title-item">
									<h2 class="title">中欧三地游通票</h2>
									<h3 class="subhead">Central Europe Triangle</h3>
								</div>
								<div class="jiage price">
									<span class="ft12">￥</span>
									<span class="ft24 fb">361</span>
									<span class="ft12">起</span>
								</div>
							</div>
							<div class="title-content">
								<div class="content-item">持有中欧三地游火车通票在有效期内可以乘搭；意大利、匈牙利、捷克的国营城际火车。及享受相关各类优惠旅游产品。路线：维也纳-布拉格-布达佩斯-维也纳；维也纳-布拉格-布达佩斯-维也纳。反方向使用也可以</div>
								<div class="bookbtnbox"><a href="#" class="bookbtn">预订</a></div>
							</div>
						</div>
					</div>
				



					

				

					<div class="mt20 pb20 text-c" >
						<pagination @handleCurrentChange="handleCurrentChange" :pageTotal="12" :page-size="4"></pagination>	
					</div>
				</div>
				<div class="list-right">
					<p class="more-title clearfix ft16"><span class="text-left hot-visa">目的地相关产品</span><a href="#" class="more fr text-right">更多</a></p>
					<div>
						<a href="#" class="clearfix more-item" v-for="(item,index) in [1,2,3,4]" :key="index">
							<div class="fl">
								<img src="../../assets/insurance/insurancebg-1.jpg" alt="" style="width:100px;height:90px">
							</div>
							<div class="fr list-item-r">
								<p class="ft14 visa-title">泰国旅游签证(多次)</p>
								<p class="ft12 gray9">有效期:12个月</p>
								<p class="ft12 gray9">最多停留:海关定</p>
								<p class="ft12 gray9">预计工作日:面试后3-5天</p>
							</div>
						</a>
					</div>
					<p class="more-title clearfix ft16"><span class="text-left hot-visa">热门申根保险产品</span><a href="#" class="more fr text-right">更多</a></p>
					<div>
						<a href="#" class="clearfix more-item" v-for="(item,index) in [1,2,3,4]" :key="index">
							<div class="fl">
								<img src="../../assets/insurance/insurancebg-1.jpg" alt="" style="width:100px;height:90px">
							</div>
							<div class="fr list-item-r ins">
								<p class="ft14 visa-title">美国“万国游踪”境外旅游保障计划-亚洲计划</p>
								<p class="ft12 gray9">最大期限：28天 可当天生效</p>
								<div class="ft12 gray9 position-r">
									<span>已被购买200次</span>
									<p class="mon">
										<span class="price ft12">￥</span>
										<span class="ft18 price fb">200</span>
									</p>
								</div>
							</div>
						</a>
					
					</div>
					<p class="more-title clearfix ft16"><span class="text-left hot-visa">热门目的地攻略</span><a href="#" class="more fr text-right">更多</a></p>
					<div>
						<a href="#" class="more-item travel-item" v-for="(item,index) in [1,2]" :key="index">
							<div>
								<img src="../../assets/insurance/insurancebg-1.jpg" alt="Alternate Text" style="width:100%;height:146px" />
								<div class="pd10">
									<p class="travel-title">不同凡响的旅行-夏威夷</p>
									<div class="mt10 mb10">
										<p class="gray6 zan"><span class="iconfont icon-liulan gray9"></span><span>34</span></p>
										<p class="gray6 zan"><span class="iconfont icon-zan gray9"></span><span>314</span></p>
										<p class="gray6 zan"><span class="iconfont icon-comment gray9"></span><span>344</span></p>
									</div>
									<div class="clearfix">
										<p class="head-message">
											<img src="../../assets/insurance/insurancebg-1.jpg" class="destination-img" />
											<span class="name">hykk</span>
										</p>
										<p class="head-date gray9">2018-12-19</p>
									</div>
								</div>
							</div>
						</a>
						
					
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var flightApi = require('@/api/flightApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;
    import pagination from '../../components/pagination.vue';
	export default {
		name: 'Home',
		components: {
			pagination
		},
		data() {
			return {
				titile: '列表',
			};
		},
		created() {
			that = this;


		},
		methods: {

		}
	};
</script>

<style lang="scss">
	@import '../../style/Europe/Europe.scss';
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"container eurail-list"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"type"},[_c('span',{staticClass:"type-t"},[_vm._v("国家/地区:")]),_vm._m(2),_c('div',[_c('el-input',{staticClass:"form-control list-input",attrs:{"type":"text","value":"","placeholder":"请输入国家"}}),_c('button',{staticClass:"list-search",attrs:{"type":"button"}},[_vm._v("搜索")])],1)]),_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"list-left"},[_vm._l(([1,2,3,4]),function(item,index){return _c('div',{key:index,staticClass:"list-i",staticStyle:{"overflow":"hidden"}},[_vm._m(3,true),_vm._m(4,true)])}),_c('div',{staticClass:"mt20 pb20 text-c"},[_c('pagination',{attrs:{"pageTotal":12,"page-size":4},on:{"handleCurrentChange":_vm.handleCurrentChange}})],1)],2),_c('div',{staticClass:"list-right"},[_vm._m(5),_c('div',_vm._l(([1,2,3,4]),function(item,index){return _c('a',{key:index,staticClass:"clearfix more-item",attrs:{"href":"#"}},[_vm._m(6,true),_vm._m(7,true)])}),0),_vm._m(8),_c('div',_vm._l(([1,2,3,4]),function(item,index){return _c('a',{key:index,staticClass:"clearfix more-item",attrs:{"href":"#"}},[_vm._m(9,true),_vm._m(10,true)])}),0),_vm._m(11),_c('div',_vm._l(([1,2]),function(item,index){return _c('a',{key:index,staticClass:"more-item travel-item",attrs:{"href":"#"}},[_vm._m(12,true)])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title"},[_c('a',{attrs:{"href":"#"}},[_c('span',[_vm._v("一起飞国际机票网")])]),_c('i',{staticClass:"iconfont icon-arrow-right icon-chalv-xiangzuo"}),_c('a',{attrs:{"href":"#"}},[_c('span',[_vm._v("欧铁")])]),_c('i',{staticClass:"iconfont icon-arrow-right icon-chalv-xiangzuo"}),_c('span',[_vm._v("通票列表页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type"},[_c('span',{staticClass:"type-t"},[_vm._v("类别:")]),_c('ul',[_c('li',[_c('span',{staticClass:"active"},[_vm._v("全部")])]),_c('li',[_c('span',[_vm._v("单国自选通票")])]),_c('li',[_c('span',[_vm._v("二国自选通票")])]),_c('li',[_c('span',[_vm._v("三国自选通票")])]),_c('li',[_c('span',[_vm._v("四国自选通票")])]),_c('li',[_c('span',[_vm._v("多国自选通票")])]),_c('li',[_c('span',[_vm._v("经典通票")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"country"},[_c('li',[_c('span',{staticClass:"active"},[_vm._v("全部")])]),_c('li',[_c('span',[_vm._v("瑞士")])]),_c('li',[_c('span',[_vm._v("法国")])]),_c('li',[_c('span',[_vm._v("意大利")])]),_c('li',[_c('span',[_vm._v("英国")])]),_c('li',[_c('span',[_vm._v("比利时")])]),_c('li',[_c('span',[_vm._v("捷克")])]),_c('li',[_c('span',[_vm._v("奥地利")])]),_c('li',[_c('span',[_vm._v("塞班")])]),_c('li',[_c('span',[_vm._v("波士顿")])]),_c('li',[_c('span',[_vm._v("南极")])]),_c('li',[_c('span',[_vm._v("瑞士")])]),_c('li',[_c('span',[_vm._v("法国")])]),_c('li',[_c('span',[_vm._v("意大利")])]),_c('li',[_c('span',[_vm._v("英国")])]),_c('li',[_c('span',[_vm._v("比利时")])]),_c('li',[_c('span',[_vm._v("捷克")])]),_c('li',[_c('span',[_vm._v("奥地利")])]),_c('li',[_c('span',[_vm._v("塞班")])]),_c('li',[_c('span',[_vm._v("波士顿")])]),_c('li',[_c('span',[_vm._v("南极")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-l fl"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/insurance/insurancebg-3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr content-r"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-item"},[_c('h2',{staticClass:"title"},[_vm._v("中欧三地游通票")]),_c('h3',{staticClass:"subhead"},[_vm._v("Central Europe Triangle")])]),_c('div',{staticClass:"jiage price"},[_c('span',{staticClass:"ft12"},[_vm._v("￥")]),_c('span',{staticClass:"ft24 fb"},[_vm._v("361")]),_c('span',{staticClass:"ft12"},[_vm._v("起")])])]),_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"content-item"},[_vm._v("持有中欧三地游火车通票在有效期内可以乘搭；意大利、匈牙利、捷克的国营城际火车。及享受相关各类优惠旅游产品。路线：维也纳-布拉格-布达佩斯-维也纳；维也纳-布拉格-布达佩斯-维也纳。反方向使用也可以")]),_c('div',{staticClass:"bookbtnbox"},[_c('a',{staticClass:"bookbtn",attrs:{"href":"#"}},[_vm._v("预订")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"more-title clearfix ft16"},[_c('span',{staticClass:"text-left hot-visa"},[_vm._v("目的地相关产品")]),_c('a',{staticClass:"more fr text-right",attrs:{"href":"#"}},[_vm._v("更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fl"},[_c('img',{staticStyle:{"width":"100px","height":"90px"},attrs:{"src":require("../../assets/insurance/insurancebg-1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr list-item-r"},[_c('p',{staticClass:"ft14 visa-title"},[_vm._v("泰国旅游签证(多次)")]),_c('p',{staticClass:"ft12 gray9"},[_vm._v("有效期:12个月")]),_c('p',{staticClass:"ft12 gray9"},[_vm._v("最多停留:海关定")]),_c('p',{staticClass:"ft12 gray9"},[_vm._v("预计工作日:面试后3-5天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"more-title clearfix ft16"},[_c('span',{staticClass:"text-left hot-visa"},[_vm._v("热门申根保险产品")]),_c('a',{staticClass:"more fr text-right",attrs:{"href":"#"}},[_vm._v("更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fl"},[_c('img',{staticStyle:{"width":"100px","height":"90px"},attrs:{"src":require("../../assets/insurance/insurancebg-1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr list-item-r ins"},[_c('p',{staticClass:"ft14 visa-title"},[_vm._v("美国“万国游踪”境外旅游保障计划-亚洲计划")]),_c('p',{staticClass:"ft12 gray9"},[_vm._v("最大期限：28天 可当天生效")]),_c('div',{staticClass:"ft12 gray9 position-r"},[_c('span',[_vm._v("已被购买200次")]),_c('p',{staticClass:"mon"},[_c('span',{staticClass:"price ft12"},[_vm._v("￥")]),_c('span',{staticClass:"ft18 price fb"},[_vm._v("200")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"more-title clearfix ft16"},[_c('span',{staticClass:"text-left hot-visa"},[_vm._v("热门目的地攻略")]),_c('a',{staticClass:"more fr text-right",attrs:{"href":"#"}},[_vm._v("更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"100%","height":"146px"},attrs:{"src":require("../../assets/insurance/insurancebg-1.jpg"),"alt":"Alternate Text"}}),_c('div',{staticClass:"pd10"},[_c('p',{staticClass:"travel-title"},[_vm._v("不同凡响的旅行-夏威夷")]),_c('div',{staticClass:"mt10 mb10"},[_c('p',{staticClass:"gray6 zan"},[_c('span',{staticClass:"iconfont icon-liulan gray9"}),_c('span',[_vm._v("34")])]),_c('p',{staticClass:"gray6 zan"},[_c('span',{staticClass:"iconfont icon-zan gray9"}),_c('span',[_vm._v("314")])]),_c('p',{staticClass:"gray6 zan"},[_c('span',{staticClass:"iconfont icon-comment gray9"}),_c('span',[_vm._v("344")])])]),_c('div',{staticClass:"clearfix"},[_c('p',{staticClass:"head-message"},[_c('img',{staticClass:"destination-img",attrs:{"src":require("../../assets/insurance/insurancebg-1.jpg")}}),_c('span',{staticClass:"name"},[_vm._v("hykk")])]),_c('p',{staticClass:"head-date gray9"},[_vm._v("2018-12-19")])])])])
}]

export { render, staticRenderFns }